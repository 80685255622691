import React from 'react'
import './App.css'
import Particles from './Components/Pacticles'
import Home from './Components/Home'


function App(){
  
  return (
<>

    <Home/>
    <Particles />
    <div className="core">
    </div>
</>
  )
}
export default App