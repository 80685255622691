import bird from './../bird.png'
import * as THREE from 'three';
import React from 'react'
import { Power0, gsap} from 'gsap';

import p0 from './../0.png'
import p1 from './../1.png'
import p2 from './../2.png'
import p3 from './../3.png'
import p4 from './../4.png'
import p5 from './../5.png'
import p6 from './../6.png'
import p7 from './../7.png'
import p8 from './../8.png'
import p9 from './../9.png'
import p10 from './../10.png'
import p11 from './../11.png'
import p12 from './../12.png'
import p13 from './../13.png'
import p14 from './../14.png'
import p15 from './../15.png'
import p16 from './../16.png'
import p17 from './../17.png'
import p18 from './../18.png'
import p19 from './../19.png'
import p20 from './../20.png'
import p21 from './../21.png'
import p22 from './../22.png'
import p23 from './../23.png'
import p24 from './../24.png'
import p25 from './../25.png'
import p26 from './../26.png'
import p27 from './../27.png'
import p28 from './../28.png'
import p29 from './../29.png'
import p30 from './../30.png'
import p31 from './../31.png'
import p32 from './../32.png'
import p33 from './../33.png'
import p34 from './../34.png'
import p35 from './../35.png'
import p36 from './../36.png'
import p37 from './../37.png'
import p38 from './../38.png'
import p39 from './../39.png'
import p40 from './../40.png'
import p41 from './../41.png'
import p42 from './../42.png'


export default function Particlesb(){

    const requestRef = React.useRef()
    const [ww, setWidth] = React.useState(window.innerWidth)
    const [wh, setHeight] = React.useState(window.innerHeight)

    const tl = new gsap.timeline();
    // let imatedata;
    let centerVector = new THREE.Vector3(0, 0, 0);
    let previousTime = 0;
    let renderer, scene, camera, particles = [], imagedata,canvas
    let mouseX = 0, mouseY = 0
    let textureLoader = new THREE.TextureLoader();
    let images  = []
    let isRotate = ww>768?true:false

    images[0]=p0
    images[1]=p1
    images[2]=p2
    images[3]=p3
    images[4]=p4
    images[5]=p5
    images[6]=p6
    images[7]=p7
    images[8]=p8
    images[9]=p9
    images[10]=p10
    images[11]=p11
    images[12]=p12
    images[13]=p13
    images[14]=p14
    images[15]=p15
    images[16]=p16
    images[17]=p17
    images[18]=p18
    images[19]=p19
    images[20]=p20
    images[21]=p21
    images[22]=p22
    images[23]=p23
    images[24]=p24
    images[25]=p25
    images[26]=p26
    images[27]=p27
    images[28]=p28
    images[29]=p29
    images[30]=p30
    images[31]=p31
    images[32]=p32
    images[33]=p33
    images[34]=p34
    images[35]=p35
    images[36]=p36
    images[37]=p37
    images[38]=p38
    images[39]=p39
    images[40]=p40
    images[41]=p41
    images[42]=p42


    React.useEffect(()=>{
            init();
        },[]
    );
    React.useEffect(() => {
            delay_till_anime()
            requestRef.current = requestAnimationFrame(render);
            return () => cancelAnimationFrame(requestRef.current);
    }, [])
    function delay_till_anime() {
        setTimeout(addMouseEvent, 7500);
    }
    
    let addMouseEvent = ()=>{
        document.addEventListener( 'mousemove', onDocumentMouseMove, false );
        document.addEventListener( 'touchstart', onDocumentTouchStart, false );
        document.addEventListener( 'touchmove', onDocumentTouchMove, false );
    }
    var getImageData = function(image) {

        var canvas = document.createElement("canvas");
        canvas.width = image.width;
        canvas.height = image.height;
    
        var ctx = canvas.getContext("2d");
        ctx.drawImage(image, 0, 0);
    
        return ctx.getImageData(0, 0, image.width, image.height);
    }

let createSpriteParticlesNew = () =>{
    scene.fog = new THREE.FogExp2( "#ffffff", 0.01 );
    var geometries = [], mapMaterials = [], sprites = []

    for(let i = 0; i<43; i++) {
        mapMaterials[i] = new THREE.PointsMaterial( { side:THREE.DoubleSide, size: 30, map: textureLoader.load(images[i]), blending: THREE.AdditiveBlending, depthTest: false, transparent:true, opacity: 0 } )
        geometries[i] = new THREE.Geometry()
    }
    let gi = 0
    for ( var i = 0; i < 10000; i ++ ) {
        gi = gi>=43?0:gi
        var vertex = new THREE.Vector3();
        vertex.x = Math.random() * 3000 - 1500;
        vertex.y = Math.random() * 3000 - 1500;
        vertex.z = Math.random() * 3000 - 1500;
        geometries[gi].vertices.push(vertex)
        gi++
    }
    for(let i=1; i<43;i++){
        var geometry = geometries[i];
        sprites[i] = new THREE.Points(geometry, mapMaterials[i]);
        sprites[i].name = 'evrika'
        sprites[i].rotation.x = Math.random() * 6;
        sprites[i].rotation.y = Math.random() * 6;
        sprites[i].rotation.z = Math.random() * 6;
        scene.add(sprites[i])
        }
}

    let drawMap = () =>{
        var geometries = []
        var mapMaterials = []
        for(let i = 0; i<=43; i++) {
            geometries[i] = new THREE.Geometry()
            mapMaterials[i] = new THREE.PointsMaterial( { side:THREE.DoubleSide, size: 3, map: textureLoader.load(images[i]), blending: THREE.AdditiveBlending, depthTest: false, transparent:true, opacity: 1 } )
        }
        let gi = 0;
        for (var y = 0, y2 = imagedata.height; y < y2; y += 2) {
            for (var x = 0, x2 = imagedata.width; x < x2; x += 2) {
                if (imagedata.data[(x * 4 + y * 4 * imagedata.width) + 3] > 128) {
                    var vertex = new THREE.Vector3();
                    vertex.x = Math.random() * 2000 - 1000;
                    vertex.y = Math.random() * 2000 - 1000;
                    vertex.z = Math.random() * 2000 -1000;
                    vertex.destination = {
                        x: x - imagedata.width / 2,
                        y: -y + imagedata.height / 2,
                        z: -400
                    };
                    vertex.speed = Math.random() / 15000 + Math.random()/50;
                    if(gi>42) gi=0
                    geometries[gi].vertices.push(vertex);
                    gi++;

                }
            }
        }

        for(let i=1; i<43;i++){
        var geometry = geometries[i];
        particles[i] = new THREE.Points(geometry, mapMaterials[i]);
        particles[i].name = 'bird'
        scene.add(particles[i])
        }
        requestRef.current = requestAnimationFrame(render);
    };

    let init = () => {
        canvas = document.getElementById('map')
        THREE.ImageUtils.crossOrigin = '';
        var needAntialias = window.devicePixelRatio === 1.0
        renderer = new THREE.WebGLRenderer({
            canvas: canvas,
            antialias: needAntialias,
            alpha: true
        });
        renderer.setPixelRatio(window.devicePixelRatio);
        renderer.setSize(ww, wh);
        renderer.setClearColor( 0x000000, 0 );
        scene = new THREE.Scene();
        camera = new THREE.PerspectiveCamera(45, ww / wh, 0.1, 200000);
        camera.far = 100000;
        camera.near = 1;
        camera.lookAt(centerVector);
        scene.add(camera);


        // createParticleCloud()
        createSpriteParticlesNew()

        var texture = THREE.ImageUtils.loadTexture(bird, undefined, function() {
            imagedata = getImageData(texture.image);
            drawMap();
        });
      window.addEventListener('resize', onResize, false);
    };

    let createParticleCloud = () => {
        // 形状データを作成
        var geometry = new THREE.Geometry();
        var numParticles = 50000;
        var SIZE = 10000;
        for (var i = 0; i < numParticles; i++) {
          geometry.vertices.push(new THREE.Vector3(SIZE * (Math.random() - 0.5), SIZE * (Math.random() - 0.5), SIZE * (Math.random() - 0.5)));
        }

        var texture = textureLoader.Load('http://ics-web.jp/lab-data/150601_threejs_mosaic/imgs/fire_particle.png');
        var material = new THREE.PointCloudMaterial({
          size: 30,
          color: "white",
          blending: THREE.AdditiveBlending,
          transparent: false,
          depthTest: false,
          opacity:0.8,
          map: texture
        });
        var mesh = new THREE.PointCloud(geometry, material);
      };

    let onResize = ()=>{
        setWidth(window.innerWidth);
        setHeight(window.innerHeight);
        renderer.setSize(ww, wh);
        camera.aspect = ww / wh;
        camera.updateProjectionMatrix();
    };

    let render = (a)=> {
        requestAnimationFrame(render);
        if(particles){
        for(var l = 0; l<43; l++)
        if(particles[l]&&particles[l]&&particles[l].geometry){ var singlePart = particles[l]
        for (var i = 0, j = singlePart.geometry.vertices.length; i < j; i++) {
            var particle = singlePart.geometry.vertices[i];
            particle.x += (particle.destination.x - particle.x) * particle.speed;
            particle.y += (particle.destination.y - particle.y) * particle.speed;
            particle.z += (particle.destination.z - particle.z) * particle.speed;
            if(a-previousTime>6500){
                particle.speed+=0.0009
            }
        }
        particles[l].geometry.verticesNeedUpdate = true;}
    }
    var time = Date.now() * 0.00001;
    camera.position.x += ( mouseX - camera.position.x ) * 0.05;
    camera.position.y += ( - mouseY - camera.position.y ) * 0.05;

    if(isRotate){
        tl.to(camera.rotation,2, {x:1, ease:Power0.easeIn })
        .to(camera.rotation, 5 ,{x:Math.sin(0), ease:Power0.easeIn})
        .to(camera.position, 3, {z:-800, ease:Power0.easeIn })
        camera.position.z=0
        isRotate = false
     } 

     for ( var i = 0; i < scene.children.length; i ++ ) {
        var object = scene.children[ i ];
        if ( object instanceof THREE.Points ) {
            if(object.name ==="evrika"&&a-previousTime>8500){
                object.rotation.y = time * ( i < 4 ? i + 1 : - ( i + 1 ) )/8;
                object.material.opacity=0.4
            }
        }
    }
    
        renderer.render( scene, camera );
        if(a-previousTime>=15000&&!isRotate){ 
            if(particles)
                for(var i=0; i<43; i++){
                    if(particles[i]){
                scene.remove(particles[i])
                particles[i]=undefined}
                    }
                particles=undefined     
        }
        camera.updateProjectionMatrix();
    }
    // function onWindowResize() {
    //     camera.aspect = ww/ wh;
    //     camera.updateProjectionMatrix();
    //     renderer.setSize( ww, wh );
    // }

    function onDocumentMouseMove( event ) {
        mouseX = event.clientX - ww/2;
        mouseY = event.clientY - wh/2;
    }

    function onDocumentTouchStart( event ) {
        if ( event.touches.length === 1 ) {
            event.preventDefault();
            mouseX = event.touches[ 0 ].pageX - ww/2;
            mouseY = event.touches[ 0 ].pageY - wh/2;
        }
    }

    function onDocumentTouchMove( event ) {
        if ( event.touches.length === 1 ) {
            event.preventDefault()
            mouseX = event.touches[ 0 ].pageX - ww/2
            mouseY = event.touches[ 0 ].pageY - wh/2
        }
    }
return(
    <canvas id='map' style={{zIndex:1}}></canvas>
)
}
